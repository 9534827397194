.MobileMenu {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  overflow: hidden;
  transition: height 200ms linear;
  ul {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      display: flex;
      position: relative;
      height: 80px;
      justify-content: center;
      align-items: center;
      font-family: "Oswald", sans-serif;
      font-size: 1em;
      &:hover {
          cursor: pointer;
          opacity: 0.5;
      }
      hr {
          position: absolute;
          bottom: 0;
          padding: 0;
          margin: 0;
          left: 20px;
          right: 20px;
          opacity: 0.2;
      }
    }
  }
}
