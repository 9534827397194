.publication-row {
    font-family: "Oswald", sans-serif;
    margin-bottom: 25px;
    text-align: left;
    font-size: 1.2em;
}

@media screen and (max-width: 800px) {
    .publication-row {
        font-size: 1em;
    }
}