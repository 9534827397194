html {
  background-color: black;
}

@font-face {
  font-family: Ballet Harmony;
  src: url('./assets/Ballet_Harmony.ttf');
}

h1, h2, h3, p {
  margin: 0;
  padding: 0;
  text-align: left;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

h1 {
  font-family: 'Ballet Harmony', serif;
  color: white;
  font-weight: 400;
  font-size: 3em;
}

h2, h3, p {
  font-family: 'Oswald', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
