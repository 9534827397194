@import "../styles/colors.scss";

.navbar {
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  h3 {
    font-family: "Ballet Harmony", serif;
    font-size: 1.5em;
    color: "white";
    font-weight: normal;
    transition: opacity 300ms ease-in-out;
    &:hover {
      opacity: .5;
      cursor: pointer;
    }
  }
  ul {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 20px 0;
    .navbar-item {
      text-decoration: none;
      list-style: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      text-align: center;
      height: 40px;
      padding: 0 40px;
      border-radius: 10px;
      font-family: "Oswald", sans-serif;
      transition: background-color 100ms ease-in;
      &.active {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .hamburger {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    display: none;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 800px) {
  .navbar {
    padding: 0 20px;
    ul {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
}
