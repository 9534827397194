@import "../../styles/colors.scss";

.contact {
  .content {
    width: 100%;
    display: flex;
    position: relative;
    .end-cover {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40%;
      max-width: 600px;
      min-width: 350px;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 99;
      display: flex;
      align-items: center;
      padding: 40px;
    }
    .button {
      padding: 10px 20px;
      background-color: $yellow;
      color: white;
      width: 100px;
      margin-top: 20px;
      border-radius: 5px;
      font-size: 0.9em;
      font-family: "Oswald", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contact {
    .content {
        // .end-cover {
        //     height: 400px;
        // }
        flex-direction: column;
      .overlay {
        width: 100%;
        
        box-sizing: border-box;
        max-width: 100%;
        min-width: 100%;
        h1 {
            font-size: 1.8em;
        }
        h3 {
            font-size: .9em;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
    .contact {
      .content {
          flex-direction: column;
        .overlay {
          width: 100%;
          
          box-sizing: border-box;
          max-width: 100%;
          padding: 40px 10px 40px 20px;
          min-width: 100%;
          h1 {
              font-size: 1.8em;
          }
          h3 {
              font-size: .9em;
          }
        }
      }
    }
  }
