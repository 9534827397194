@import '../styles/colors.scss';

.banner {
    width: 100%;
    height: 500px;
    padding: 60px;
    margin-bottom: 60px;
    position: relative;
    z-index: 90;
    margin-top: 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: url('../assets/img/bg.jpg');
    background-position-y: 20%;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    background-color: black;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.4);
    .info-rows {
        margin-top: 10px;
        color: rgba(255,255,255,0.8);
    }
}

.some-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .icon {
        height: 35px;
        width: 35px;
        font-size: 35px;
        opacity: .9;
        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
            margin-right: 15px;
    }
}

.info-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    line-height: 1.2;
    max-width: 420px;
    letter-spacing: .5px;
    text-align: left;
        font-family: 'Oswald', sans-serif;
        font-size: 1.1em;
    &:hover {
        cursor: pointer;
        opacity: .5;
    }
    .icon {
        color: $yellow;
        height: 25px;
        width: 25px;
        margin-right: 15px;
        flex-shrink: 0;
    }
}

.header-container {
    display: flex;
    flex-direction: column;
    .name-container {
        display: flex;
        align-items: center;
        margin-top: 5px;
        h3 {
            margin-left: 3px;
        }
    }
    h3 {
        opacity: 0.6;
        letter-spacing: 2px;
        font-weight: 400;
        font-size: 1.1em;
    }
}

@media screen and (max-width: 800px) {
    .banner {
        background: url('../assets/img/bg.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: black;
        height: 450px;
        padding: 40px;
    }
    .info-row {
        font-size: 1em;
    }
    h1 {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 350px) {
    .banner {
        height: 420px;
    }
    h1 {
        font-size: 2.3em;
    }
}

@media screen and (max-width: 320px) {
    .banner {
        height: 420px;
    }
    h1 {
        font-size: 2em;
    }
}