@import './styles/colors.scss';

.App {
  text-align: center;
  position: relative;
  background-color: $darkGray;
  .bg {
    position: absolute;
    top: -100px;
    left: 0; 
    right: 0;
    bottom: 0;
    z-index: 0;
    background: url('./assets/img/bgpattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    opacity: 0.6;
  }
  .section {
    padding: 60px;
    padding-top: 0;
    box-sizing: border-box;
    width: 100%;
    .section-content {
      padding: 0 40px;
    }
    p {
      font-size: 1.3em;
      font-weight: lighter;
    }
    .highlight p {
      font-weight: bold;
      font-size: 1.4em;
      max-width: 430px;
      padding-bottom: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .App {
    .section {
      padding: 40px;
      padding-top: 0;
      .highlight p {
        font-size: 1.3em;
      }
      .section-content {
        padding: 0;
      }
    }
  }
}
