.collaboration-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 70px;
  }

  hr {
    position: absolute;
    bottom: -35px;
    width: 100%;
    opacity: 0.05;
  }

  &:last-child {
    hr {
      display: none;
    }
  }

  .text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 500px;
    p {
      font-weight: 400;
      opacity: 0.6;
    }
    .description {
      padding: 20px 0;
      font-weight: lighter;
      opacity: 1;
    }
    h3,
    p {
      margin: 0;
      padding: 0;
      text-align: right;
    }
    h3 {
      font-size: 1.6em;
      margin-bottom: 5px;
    }
  }
  img {
    height: 250px;
    width: 450px;
    max-width: 95%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1200px) {
  .collaboration-block {
    flex-direction: column-reverse;
    &:not(:last-child) {
      margin-bottom: 70px;
    }
    hr {
        bottom: -50px;
      }
    .text-container {
      align-items: center;
      max-width: 100%;
      margin-bottom: 30px;
      h3,
      p {
        text-align: center;
      }
      h3 {
        font-size: 1.3em;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
}
