@import '../styles/colors.scss';

.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    padding-left: 20px;
    margin-top: 20px;
    .line {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $yellow;
        border-radius: 5px;
    }
    .button {
        padding: 15px 20px;
        background-color: $yellow;
        color: white;
        flex-shrink: 1;
        border-radius: 5px;
        margin-left: 40px;
        font-family: "Oswald",sans-serif;
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 600;
        white-space: nowrap;
        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }
    h2 {
        color: $yellow;
    }
    p {
        color: $yellow;
        font-size: 0.7em;
        opacity: .8;
    }
    hr {
        flex: 1;
        opacity: 1;
        margin-left: 40px;
        border-color: white;
        opacity: 0.2;
    }
}

@media screen and (max-width: 400px) {
    .section-header {
        hr {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    .project-section {
        hr {
            display: none;
        }
    }
}

@media screen and (max-width: 800px) {
    .publications {
        hr {
            margin-left: 15px;
        }
        .button {
            margin-left: 15px;
        }
    }
    .section-header {
        h2 {
            font-size: 1.4em;
        }
        .button {
            font-size: .8em;
        }
    }
}


@media screen and (max-width: 460px) {
    .section-header {
        flex-wrap: wrap;
        hr {
            margin-left: 40px;
        }
    .button {
        margin-top: 15px;
        margin-bottom: 10px;
        margin-left: 0;
        padding: 10px 15px;
    }
}
}